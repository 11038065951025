<template>
    <AppHead :title="title" />

    <div
        id="mainContainer"
        class="relative flex flex-col h-screen overflow-auto bg-gray-50"
    >
        <!-- Navigation -->
        <header
            :class="[
                theme == 'light'
                    ? 'bg-white text-gray-900'
                    : 'bg-transparent text-white hover:text-gray-100',
                'absolute inset-x-0 top-0 z-50',
            ]"
        >
            <nav
                class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <div class="flex lg:flex-1">
                    <Link :href="route('home')" class="-m-1.5 p-1.5">
                        <span class="sr-only">{{ app.name }}</span>
                        <ApplicationLogo class="w-auto h-10" :color="theme == 'light' ? 'black' : 'white'"/>
                    </Link>
                </div>
                <div class="flex lg:hidden">
                    <button
                        type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                        @click="mobileMenuOpen = true"
                    >
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <Link
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        class="text-sm font-semibold leading-6"
                    >
                        {{ item.name }}
                    </Link>
                </div>
                <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a :href="app.locales.href" class="text-sm font-medium">
                        {{ app.locales.label }}
                    </a>
                </div>
            </nav>
            <Dialog
                as="div"
                class="lg:hidden"
                @close="mobileMenuOpen = false"
                :open="mobileMenuOpen"
            >
                <div class="fixed inset-0 z-50" />
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                >
                    <div class="flex items-center justify-between">
                        <Link :href="route('home')" class="-m-1.5 p-1.5">
                            <span class="sr-only">{{ app.name }}</span>
                            <ApplicationLogo
                                class="w-auto h-10"
                                color="black"
                            />
                        </Link>
                        <button
                            type="button"
                            class="-m-2.5 rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <Link
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :href="item.href"
                                    class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {{ item.name }}
                                </Link>
                            </div>
                            <div class="py-6">
                                <a
                                    :href="app.locales.href"
                                    class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {{ app.locales.label }}
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

        <div class="flex-grow">
            <slot />
        </div>

        <AppFooter class="flex-shrink-0" />
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import AppHead from "@/Layouts/Partials/Head.vue";
import AppFooter from "@/Layouts/Partials/Footer.vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { translate as t } from "@/i18n/i18n";
import ApplicationLogo from "&/Components/Logo/ApplicationLogo.vue";

const props = defineProps({
    title: String,
    theme: {
        type: String,
        default: "light",
    },
});

const mobileMenuOpen = ref(false);

const app = computed(() => usePage().props.app);

const navigation = [
    {
        name: t("app.navigation.about"),
        href: "/#about",
    },
    {
        name: t("app.navigation.menu"),
        href: route("menu"),
    },
    {
        name: t("app.navigation.find_us"),
        href: route("location"),
    },
];
</script>
